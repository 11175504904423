import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { NextRouter, useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import clsx from 'clsx'

import { Area, Locale } from 'generated/graphcms'
import FilterSelect, { Item } from '@/components/ui/FilterSelect/FilterSelect'
import FormInputNumber from '@/components/ui/FormInputNumber/FormInputNumber'
import IconClose from '@/components/ui/Icon/IconClose'
import IconUsers from '@/components/ui/Icon/IconUsers'
import IconFilter from '@/components/ui/Icon/IconFilter'
import Button from '@/components/ui/Button/Button'
import FormGroup from '@/components/ui/FormGroup/FormGroup'

import usePreviewGroupSize from 'hooks/usePreviewGroupSize'
import { APP_ROUTES, MAX_GUESTS, MIN_GUESTS } from 'utils/constants'
import { triggerHotJarEvent } from '../../../utils/tracking'

interface Props {
  types: string[]
  areas: Area[]
  areaSlug?: string
  query?: ParsedUrlQuery
}

export const querifyItems = (items: Item[]): string => items.map((item) => item.id).join(',')
export const unquerifyItems = (queryParam: string | string[], allItems: Item[]): Item[] => {
  // this function takes a querified param string and returns a filtered list of all items
  // that are included, based on allItems
  if (typeof queryParam !== 'string') {
    return []
  }

  const ids = queryParam.split(',')

  return allItems.filter((item) => ids.includes(item.id))
}

type QueryObject = { [id: string]: string | number | boolean }
type FilterSelection = {
  selectedTypes: Item[]
  selectedAreas: Item[]
  selectedGroupSize: number
  selectedPrices: Item[]
}

const callOnMdOrUp = (func: () => void) => {
  if (window.matchMedia('(min-width: 768px)').matches) {
    func()
  }
}

const FEATURE_FLAG_TYPE_FILTER = false
const FEATURE_FLAG_PRICE_FILTER = false

const VenueFilter: React.FC<Props> = ({
  types = [],
  areas = [],
  areaSlug = undefined,
  query = {},
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { locale = Locale.En } = router

  const availableAreas = useMemo(
    () => areas.map((area) => ({ id: area.slug, label: area.title })),
    [areas]
  )

  const availableTypes = useMemo(
    () =>
      types.map((title) => ({
        id: title,
        label: title,
      })),
    [types]
  )

  const availablePrices = useMemo(
    () => [
      { id: '0-100', label: t('filters.priceRange.upTo', { amount: '€100' }) },
      { id: '100-200', label: t('filters.priceRange.range', { amount: '€100-200' }) },
      { id: '200-300', label: t('filters.priceRange.range', { amount: '€200-300' }) },
      { id: '300-above', label: t('filters.priceRange.above', { amount: '€300' }) },
    ],
    [t]
  )

  const preselectedAreaFromSlug = useMemo<Item[]>(
    () => availableAreas.filter((item) => item.id === areaSlug),
    [availableAreas, areaSlug]
  )

  const [selectedTypesDisplayValue, setSelectedTypesDisplayValue] = useState<Item[]>([])
  // states that represent what the user has selected on the UI
  const [selectedAreasDisplayValue, setSelectedAreasDisplayValue] =
    useState<Item[]>(preselectedAreaFromSlug)

  const [selectedGroupSizeDisplayValue, setSelectedGroupSizeDisplayValue] = useState<
    string | number
  >(MIN_GUESTS)

  const [selectedPriceDisplayValue, setSelectedPriceDisplayValue] = useState<Item[]>([])

  const realtimeFilters = useRef<FilterSelection>({
    selectedTypes: selectedTypesDisplayValue,
    selectedAreas: selectedAreasDisplayValue,
    selectedGroupSize: MIN_GUESTS,
    selectedPrices: selectedPriceDisplayValue,
  })

  const setSelectedTypes = (value: Item[]) => {
    setSelectedTypesDisplayValue(value)
    realtimeFilters.current.selectedTypes = value
  }

  const setSelectedAreas = (value: Item[]) => {
    setSelectedAreasDisplayValue(value)
    realtimeFilters.current.selectedAreas = value
  }

  const setSelectedGroupSize = (value: number) => {
    setSelectedGroupSizeDisplayValue(value)
    realtimeFilters.current.selectedGroupSize = value
  }

  const setSelectedPrices = (value: Item[]) => {
    setSelectedPriceDisplayValue(value)
    realtimeFilters.current.selectedPrices = value
  }

  useEffect(() => {
    // this should happen when navigating between areas only (footer)
    if (preselectedAreaFromSlug.length <= 0) {
      // this happens when the user moves from a slug-page (e.g. berlin region) to a more
      // refined search result -> we don't do anything
      return
    }
    setSelectedGroupSize(MIN_GUESTS)
    setSelectedAreas(preselectedAreaFromSlug)
    setSelectedPrices([])
    setSelectedTypes([])
  }, [preselectedAreaFromSlug])

  const shouldIgnoreNextQueryUpdate = useRef(false)

  useEffect(() => {
    // on updated query, we reflect those changes in our UI, and update the realtime filters

    if (!shouldIgnoreNextQueryUpdate.current) {
      if (query.types) {
        setSelectedTypes(unquerifyItems(query.types, availableTypes))
      } else {
        setSelectedTypes([])
      }

      if (query.areas) {
        setSelectedAreas(unquerifyItems(query.areas, availableAreas))
      } else {
        setSelectedAreas(preselectedAreaFromSlug)
      }

      if (query.groupSize && Number.isInteger(Number(query.groupSize))) {
        setSelectedGroupSize(Number(query.groupSize))
      } else {
        setSelectedGroupSize(MIN_GUESTS)
      }

      if (query.prices) {
        setSelectedPrices(unquerifyItems(query.prices, availablePrices))
      } else {
        setSelectedPrices([])
      }
    } else {
      shouldIgnoreNextQueryUpdate.current = false
    }
  }, [availableTypes, availableAreas, preselectedAreaFromSlug, availablePrices, query])

  const currentQuery = useRef<QueryObject>()

  const updateQuery = (theRouter: NextRouter) => {
    const newQuery: QueryObject = {}

    if (realtimeFilters.current.selectedTypes.length > 0) {
      newQuery.types = querifyItems(realtimeFilters.current.selectedTypes)
    }

    if (realtimeFilters.current.selectedAreas.length > 0) {
      newQuery.areas = querifyItems(realtimeFilters.current.selectedAreas)
    }

    if (realtimeFilters.current.selectedGroupSize !== MIN_GUESTS) {
      newQuery.groupSize = realtimeFilters.current.selectedGroupSize
    }

    if (realtimeFilters.current.selectedPrices.length > 0) {
      newQuery.prices = querifyItems(realtimeFilters.current.selectedPrices)
    }

    if (isEqual(newQuery, currentQuery.current)) {
      return
    }

    shouldIgnoreNextQueryUpdate.current = true
    currentQuery.current = newQuery

    if (Object.keys(newQuery).length > 0) {
      theRouter.push({ pathname: APP_ROUTES.VENUES_SEARCH, query: newQuery })
    } else {
      theRouter.push({ pathname: APP_ROUTES.VENUES })
    }
  }

  /// auto search related functionality
  const debouncedUpdateQuery = useRef(debounce(updateQuery, 400))

  const debouncedUpdateQueryOnDesktop = () =>
    callOnMdOrUp(() => debouncedUpdateQuery.current(router))

  const { setLastSearchedGroupSize } = usePreviewGroupSize()

  const changeSelectedArea = (selection: Item[]) => {
    if (selection.length === 0) {
      const event = locale === Locale.De ? 'selected_any_area_de' : 'selected_any_area'
      triggerHotJarEvent(event)
    }

    setSelectedAreas(selection)
    debouncedUpdateQueryOnDesktop()
  }

  const changeSelectedType = (selection: Item[]) => {
    // TODO triggerHotJarEvent ?

    setSelectedTypes(selection)
    debouncedUpdateQueryOnDesktop()
  }

  const changeGroupSizeInput = (value: number) => {
    setSelectedGroupSize(value)
    setLastSearchedGroupSize(value)
    debouncedUpdateQueryOnDesktop()
  }

  const changeSelectedPrice = (selection: Item[]) => {
    // TODO triggerHotJarEvent ?

    setSelectedPrices(selection)
    debouncedUpdateQueryOnDesktop()
  }

  const onGroupSizeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGroupSizeDisplayValue(event.target.value)

    const newSize = parseInt(event.target.value, 10)

    if (!Number.isNaN(newSize)) {
      changeGroupSizeInput(newSize)
    }
  }

  const onGroupSizeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const valueOnBlur = parseInt(event.target.value, 10)

    if (Number.isNaN(valueOnBlur)) {
      changeGroupSizeInput(MIN_GUESTS)
    }
  }

  // on component umount we cancel any remaining search attempt
  useEffect(() => () => debouncedUpdateQuery.current.cancel(), [])

  // mobile filter related code
  const [shouldShowMobileFilter, setShouldShowMobileFilter] = useState(false)

  // background cache to be able to "undo" filter changes on mobile
  const filterCache = useRef<FilterSelection | null>(null)

  const showMobileFilter = () => {
    document.body.classList.add('overflow-hidden')
    setShouldShowMobileFilter(true)

    const selectedGroupSize =
      typeof selectedGroupSizeDisplayValue === 'number' ? selectedGroupSizeDisplayValue : MIN_GUESTS

    filterCache.current = {
      selectedTypes: selectedTypesDisplayValue,
      selectedAreas: selectedAreasDisplayValue,
      selectedGroupSize,
      selectedPrices: selectedPriceDisplayValue,
    }
  }

  const hideMobileFilter = () => {
    document.body.classList.remove('overflow-hidden')
    setShouldShowMobileFilter(false)
  }

  const cancelMobileFilter = () => {
    if (filterCache.current) {
      setSelectedAreas(filterCache.current.selectedAreas)
      setSelectedGroupSize(filterCache.current.selectedGroupSize)
    }

    hideMobileFilter()
  }

  const selectedFilterAreas = useMemo(() => {
    if (selectedAreasDisplayValue.length > 0) {
      return selectedAreasDisplayValue
        .map((area) => area.label)
        .sort()
        .join(', ')
    }
    return t('filters.mobilePlaceholderAnyArea')
  }, [selectedAreasDisplayValue, t])

  const onMobileSearchClick = () => {
    updateQuery(router)
    hideMobileFilter()
  }

  return (
    <>
      <div className="md:hidden">
        <button
          type="button"
          onClick={showMobileFilter}
          className="flex h-12 w-full items-center justify-between overflow-hidden rounded-lg border border-gray-200 px-4 py-0 text-sm"
        >
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            <span>
              {selectedFilterAreas}
              {' · '}
              {selectedGroupSizeDisplayValue} {t('filters.guests')}
            </span>
          </div>
          <div className="ml-2 w-6 flex-shrink-0 text-gray-600">
            <IconFilter size="24" />
          </div>
        </button>
      </div>

      <div
        className={clsx(
          'duration-400 fixed bottom-0 left-0 z-modal h-[100vh] w-full transform transition-transform ease-in-out md:static md:z-10 md:h-auto md:transform-none',
          shouldShowMobileFilter ? 'translate-y-0' : 'translate-y-full'
        )}
      >
        <div
          className={clsx(
            'absolute left-0 top-0 h-full w-full bg-gray-500 transition-opacity duration-200 ease-in-out md:hidden',
            shouldShowMobileFilter ? 'opacity-80' : 'opacity-0'
          )}
        />

        <form className="absolute bottom-0 left-0 h-[82%] w-full overflow-y-auto rounded-t-2xl bg-white px-4 pt-6 md:static md:h-auto md:overflow-y-visible md:rounded-none md:bg-transparent md:p-0">
          <div className="mb-4 flex items-center justify-between border-b border-gray-200 pb-4 md:hidden">
            <div className="text-sm font-semibold">Filters</div>
            <button type="button" onClick={cancelMobileFilter}>
              <IconClose size="20" />
            </button>
          </div>

          <div className="grid gap-y-5 md:grid-cols-2 md:gap-x-5 md:gap-y-0 xl:grid-cols-4">
            <FormGroup htmlFor="region" label={t('filters.labels.region')}>
              <FilterSelect
                wildcardLabel={t('filters.anyArea')}
                items={availableAreas}
                selectedItems={selectedAreasDisplayValue}
                onSelectedItemsChange={changeSelectedArea}
                onClose={debouncedUpdateQueryOnDesktop}
                checkType="checkbox"
                onOpen={debouncedUpdateQuery.current.cancel}
              />
            </FormGroup>

            {FEATURE_FLAG_TYPE_FILTER && (
              <FormGroup label={t('filters.labels.types')}>
                <FilterSelect
                  wildcardLabel={t('filters.anyVenueType')}
                  items={availableTypes}
                  selectedItems={selectedTypesDisplayValue}
                  onSelectedItemsChange={changeSelectedType}
                  onClose={debouncedUpdateQueryOnDesktop}
                  checkType="checkbox"
                  onOpen={debouncedUpdateQuery.current.cancel}
                />
              </FormGroup>
            )}

            <FormGroup htmlFor="guests" label={t('filters.labels.groupSize')}>
              <FormInputNumber
                id="groupSize"
                value={selectedGroupSizeDisplayValue}
                min={MIN_GUESTS}
                step={1}
                max={MAX_GUESTS}
                tooltipMin={t('filters.guestsMinHint', { count: MIN_GUESTS })}
                tooltipMax={t('filters.guestsMaxHint', { count: MAX_GUESTS })}
                Icon={IconUsers}
                onChange={onGroupSizeInputChange}
                onBlur={onGroupSizeBlur}
              />
            </FormGroup>

            {FEATURE_FLAG_PRICE_FILTER && (
              <FormGroup label={t('filters.labels.pricePersonNight')}>
                <FilterSelect
                  wildcardLabel={t('filters.anyPriceCategory')}
                  items={availablePrices}
                  selectedItems={selectedPriceDisplayValue}
                  onSelectedItemsChange={changeSelectedPrice}
                  onClose={debouncedUpdateQueryOnDesktop}
                  checkType="checkbox"
                  onOpen={debouncedUpdateQuery.current.cancel}
                />
              </FormGroup>
            )}
          </div>

          <div className="my-6 md:hidden">
            <Button
              variant="secondary"
              type="button"
              className="w-full"
              onClick={onMobileSearchClick}
            >
              {t('filters.searchButton')}
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default VenueFilter
